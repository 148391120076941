<template>
  <div class="content-div">
    <h1>Account Policy</h1>
    <p>The Liquor Licensing Board (LLB), a statutory body established under the Dutiable Commodities (Liquor) Regulations who is responsible for the licensing of premises used for the sale or supply of liquor for consumption on the premises, has given consents to accept applications and documents in form of electronic records.</p>
    <p>For security reason, personalised on-line services are provided to users with accounts only. If you do not have an account, please provide your personal information and complete the registration process online to request for an user account. Please note that this account is only for online licence services related to liquor / club liquor licences.</p>
    <p>For any change of personal particulars afterwards, you shall amend your account profile after successful login to the system.</p>
    <v-expansion-panels class="mb-8" multiple > 
      <OlsExpansion :order="special.order" title="Applicant of new licence or licence transfer" :type="1">
        <p>You need to provide your name, location of premises, phone number, correspondence address and e-mail address to create an account online. You have to download the Password Request Form and sign on it. Upon receiving your signed Password Request Form, a password for your user account will be assigned to you emailed to your correspondence address. You can use your account to:</p>
        <div class="s-li">
          <div>•</div>
          <p>Submit new licence or licence transfer application;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Check status of submitted application;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Read messages from the Licensing Office; and</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Make payment (if necessary)</p>
        </div>
        <p>Once your application for new licence / licence transfer has been approved, your user account could be used to –</p>
        <div class="s-li">
          <div>•</div>
          <p>Apply for renewal / amendment;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Apply for licence copy / temporary absence of licensee / employment of young persons;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Notify the Licensing Office the renaming of licensee name or building related to the premises address;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Check status of submitted applications;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Read messages, if any, from the Licensing Office; and</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Make payment (if necessary).</p>
        </div>
        <p>If you do not yet have an online user account but an application has been submitted to the Licensing Office, a user account will be created for you once the Licensing Office starts to process your application. User name and password for the account will be delivered to you by post to your correspondence address together with the acknowledgement letter.</p>
        <p>If you do not activate the assigned online user account to submit an application but deliver the application in person or by post to the Licensing Office, a fresh user account will be assigned for you once the Licensing Office starts to process your application. User name and password for the account will be delivered to you by post to your correspondence address together with the acknowledgement letter.</p>

      </OlsExpansion>
      <OlsExpansion :order="special.order" title="Licensee" :type="1">
        <p>Accounts have been created for existing licensees and letters with user names and passwords assigned or approved by LLB will be delivered to the licence holders in batches by post. You can then use your account to –</p>
        <div class="s-li">
          <div>•</div>
          <p>Apply for renewal / amendment;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Apply for licence copy / employment of young persons;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Notify the Licensing Office the renaming of licensee name or building related to the premises address;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Check status of submitted applications;</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Read messages, if any, from the Licensing Office; and</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Make payment (if necessary).</p>
        </div>
        <p>If you wish to submit new licence or licence transfer application, you need to provide your name, premises address, phone number, correspondence address and e-mail address to create an account online for making this application.</p>

      </OlsExpansion>
      <OlsExpansion :order="special.order" title="Validity period of user accounts" :type="1">
        <p>Your user account will be purged automatically under the following circumstances:</p>
        <div class="s-li">
          <div>•</div>
          <p>You do not have any <strong>in-progress</strong> application for new licence / licence transfer (e.g. you have not submitted any application to the Licensing Officer or the application has been refused / withdrawn / abandoned) <strong>and</strong> your account has been inactive for <strong>six months</strong>; or</p>
        </div>
        <div class="s-li">
          <div>•</div>
          <p>Your liquor licence or club liquor licence is no longer valid (e.g. expired, cancelled, revoked, transferred)</p>
        </div>
      </OlsExpansion>
    </v-expansion-panels>
  
  </div>
</template>

<script>
import BoldLine from '../../components/misc/bold-line'
import OlsExpansion from '../../components/misc/ols-expansion'

export default {
  name: 'InPolicy',
  components: {
    BoldLine,
    OlsExpansion,
  },
  data: function () {
    return {
      items:[
          
      ],
      special:{
          order: 11,
          title:'I cannot use PPS for online payment recently. I have been using PPS payment in the past. Why does this happen and what can I do?',
          content:'<img :src="logo" alt="Error message in Internet Explorer" width="234" height="294">'
      },
      publicPath: process.env.BASE_URL
    }
  },
}
</script>

<style lang="scss">
p.expansion{
  strong.i{
       font-style: italic;
  }
  span{
    word-wrap: break-word;
  }
}
a{
      text-decoration: none !important;
    p{
      color:$ols-primary !important;
    }
    line-break: anywhere;
}

#video-frame{
  max-width:850px;
  max-height:480px;
  width:100%;
  height:100%;
}

.s-li{
    display: flex;
    align-items: flex-start;
    margin-bottom:12px;
    div:first-child{
        color: $ols-secondary;
        font-weight: bold;
        font-size:2em;
        margin:-6.5px 16px 0px 20px;
    }
}

.content-div h3{
  font-size:2.125em;
}

ol,.expansion ul.expansion{
  margin-left:140px;
  
  li{
    margin-bottom:12px;
    p{
      margin:0px;
      span{
        word-wrap: break-word;
      }
    }
  }
}

h5 a {
  text-decoration: underline !important;
}

img.expansion{
  margin-left:60px;
  margin-bottom:16px;
}
//md and down
@media screen and (max-width: 1263px) {
  

}

</style>